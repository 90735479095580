
import { defineComponent, reactive, toRefs, watch, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Row, Col, Icon, Search, Button, Toast } from "vant";
import { apiBiPeUserList } from "@/api/modules/app";

export default defineComponent({
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Search.name]: Search,
    [Button.name]: Button,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const {
      depart_bazaar,
      depart_project,
      depart_design,
      fnumber,
      isrespposition,
      name,
    } = route.query;
    const dataMap = reactive({
      name: "",
      isLoading: true,
      loading: true,
      comname: "" as any,
      currentType: {} as any,
      dataList: {} as any,
      showAction: false,
      typeList: [] as any,
      activeIds: [] as number[],
    });
    dataMap.comname = name;

    const back = () => {
      router.go(-1);
    };
    const toggle = () => {
      dataMap.showAction = true;
    };

    const doScroll = (event: any) => {
      const scrollLeft = event.target.scrollLeft;
      const scrollBoxs = document.getElementsByClassName("js_scroll_box");
      for (let i = 0; i < scrollBoxs.length; i++) {
        scrollBoxs[i].scrollLeft = scrollLeft;
      }
    };
    const onSelect = (event: any) => {
      dataMap.currentType = {
        name: event.name,
        value: event.value,
      };
      getData();
    };
    const getData = () => {
      dataMap.loading = true;
      dataMap.dataList = {};
      apiBiPeUserList({
        name: dataMap.name,
        fnumber: fnumber,
        type: dataMap.currentType.value,
        isrespposition: isrespposition,
      }).then((res: any) => {
        if (res.status == 200) {
          res.tags.forEach((el: any) => {
            el.width = el.width ? el.width : 6;
          });
          dataMap.dataList = res;
          setTimeout(() => {
            dataMap.activeIds = [];
            showAll();
            initScroll(res.tags);
            dataMap.loading = false;
          }, 300);
        } else {
          dataMap.loading = false;
        }
      });
    };
    const initTypeList = () => {
      if (depart_bazaar === "1") {
        dataMap.typeList.push({
          name: "业务部",
          value: "1",
        });
      }
      if (depart_design === "1") {
        dataMap.typeList.push({
          name: "设计部",
          value: "2",
        });
      }
      if (depart_project === "1") {
        dataMap.typeList.push({
          name: "工程部",
          value: "3",
        });
      }
      if (dataMap.typeList.length) {
        dataMap.currentType = dataMap.typeList[0];
        getData();
      } else {
        Toast("您无查看任何部门的权限");
      }
    };
    initTypeList();
    const initScroll = (tags: any) => {
      const scrollContent = document.getElementsByClassName("js_scroll_content");
      let widthLen = 0;
      for (let n = 1; n < tags.length; n++) {
        widthLen = widthLen + tags[n].width;
      }
      for (let i = 0; i < scrollContent.length; i++) {
        let el = <HTMLElement>scrollContent[i];
        el.style.width = widthLen + "rem";
      }
      if (tags && tags[0] && tags[0].width) {
        const fixedTdH = document.getElementsByClassName("js_fixed_td_h");
        for (let e = 0; e < fixedTdH.length; e++) {
          let el = <HTMLElement>fixedTdH[e];
          el.style.paddingLeft = tags[0].width + "rem";
        }
      }
    };

    const checkIndex = (index: number) => {
      if (dataMap.activeIds.length === 0) {
        dataMap.activeIds.push(index);
        return;
      }
      if (dataMap.activeIds.indexOf(index) != -1) {
        for (let i = 0; i < dataMap.activeIds.length; i++) {
          if (dataMap.activeIds[i] == index) {
            dataMap.activeIds.splice(i, 1);
          }
        }
      } else {
        dataMap.activeIds.push(index);
      }
    };

    const showAll = () => {
      if (dataMap.dataList.data_list.length < dataMap.activeIds.length) {
        dataMap.activeIds = [];
      } else {
        dataMap.activeIds = [];
        for (let i = 0; i < dataMap.dataList.data_list.length; i++) {
          dataMap.activeIds.push(i);
        }
        dataMap.activeIds.push(-1);
      }
    };
    watch(
      () => dataMap.name,
      () => {
        getData();
      }
    );
    return {
      ...toRefs(dataMap),
      back,
      checkIndex,
      toggle,
      doScroll,
      onSelect,
      showAll,
    };
  },
});
