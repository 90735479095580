<template>
  <div class="list">
    <van-nav-bar
      :title="currentType.name ? currentType.name : '暂无部门'"
      :left-text="comname"
      left-arrow
      @click-left="back"
      @click-right="toggle"
    >
      <template #right>
        <span class="f-s-13 f-c-b" v-if="typeList.length - 1"
          >切换部门<i class="iconfont icon-toggle f-c-b f-s-13"></i
        ></span>
      </template>
    </van-nav-bar>
    <van-row>
      <van-col span="19">
        <van-search v-model="name" placeholder="请输入姓名" />
      </van-col>
      <van-col span="5" style="padding-top: 12px">
        <van-button type="primary" size="small" @click="showAll">
          {{
            dataList.data_list && dataList.data_list.length + 1 == activeIds.length
              ? "一键折叠"
              : "一键展开"
          }}
        </van-button>
      </van-col>
    </van-row>
    <div class="table pt-r">
      <div v-show="!loading">
        <div class="first-line sticky js_fixed_td_h table_row">
          <div class="scroll_box js_scroll_box" @scroll="doScroll">
            <div class="js_scroll_content">
              <div
                :class="['table_col', tagId == 0 ? 'fixed_td' : '']"
                :style="{
                  width: tag.width + 'rem',
                }"
                v-for="(tag, tagId) in dataList.tags"
                :key="tagId"
              >
                {{ tag.lable }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table_content">
        <template v-if="dataList.data_list && dataList.data_list.length">
          <div v-for="(data, ids) in dataList.data_list" :key="ids">
            <div
              :class="[
                'table_row',
                activeIds.indexOf(ids) > -1 ? 'last-line' : 'deactive-title',
              ]"
              @click="checkIndex(ids)"
            >
              <div
                class="table_col"
                :style="{
                  width:
                    dataList.tags && dataList.tags[0] && dataList.tags[0].width + 'rem',
                }"
              >
                {{ data.title }}
              </div>
              <div>
                <i
                  :class="[
                    'iconfont icon-down',
                    activeIds.indexOf(ids) > -1 ? 'up' : 'down',
                  ]"
                ></i>
              </div>
            </div>
            <div
              :class="[
                'table_row',
                activeIds.indexOf(ids) > -1 ? 'active-data' : 'deactive-total',
              ]"
              v-show="activeIds.indexOf(ids) > -1"
            >
              <div
                class="js_fixed_td_h table_row"
                v-if="data.years_user && data.years_user.length"
              >
                <div>
                  <div class="scroll_box js_scroll_box" @scroll="doScroll">
                    <div class="js_scroll_content">
                      <div
                        v-for="(yearData, idx) in data.years_user"
                        :key="idx"
                        class="table_bg"
                      >
                        <div
                          :class="['table_col', tagId == 0 ? 'fixed_td' : '']"
                          :style="{
                            width: tag.width + 'rem',
                          }"
                          v-for="(tag, tagId) in dataList.tags"
                          :key="tagId"
                        >
                          {{ yearData[tag.key] }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="data-none text-c f-s-14 f-c-c">
                <p>暂无记录</p>
              </div>
            </div>
          </div>
        </template>
        <div v-else-if="loading" class="data-none text-c f-s-14 f-c-c">
          <p>数据加载中</p>
        </div>
        <div v-else class="data-none text-c f-s-14 f-c-c">
          <p>暂无记录</p>
        </div>

        <div v-if="dataList.years && dataList.years.length">
          <div
            :class="[
              'table_row',
              activeIds.indexOf(-1) > -1 ? 'active-total' : 'deactive-total',
            ]"
            @click="checkIndex(-1)"
          >
            <div
              class="table_col"
              :style="{
                width:
                  dataList.tags && dataList.tags[0] && dataList.tags[0].width + 'rem',
              }"
            >
              合计
            </div>
            <div>
              <i
                :class="[
                  'iconfont icon-down',
                  activeIds.indexOf(-1) > -1 ? 'up' : 'down',
                ]"
              ></i>
            </div>
          </div>
          <div
            :class="[
              'table_row',
              activeIds.indexOf(-1) > -1 ? 'active-data' : 'deactive-total',
            ]"
            v-show="activeIds.indexOf(-1) > -1"
          >
            <div class="js_fixed_td_h table_row">
              <div>
                <div class="scroll_box js_scroll_box" @scroll="doScroll">
                  <div class="js_scroll_content">
                    <div
                      v-for="(yearData, idx) in dataList.years"
                      :key="idx"
                      class="table_bg"
                    >
                      <div
                        :class="['table_col', tagId == 0 ? 'fixed_td' : '']"
                        :style="{
                          width: tag.width + 'rem',
                        }"
                        v-for="(tag, tagId) in dataList.tags"
                        :key="tagId"
                      >
                        {{ yearData[tag.key] }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-action-sheet
      v-model:show="showAction"
      cancel-text="取消"
      :actions="typeList"
      close-on-click-action
      @select="onSelect"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs, watch, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Row, Col, Icon, Search, Button, Toast } from "vant";
import { apiBiPeUserList } from "@/api/modules/app";

export default defineComponent({
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Search.name]: Search,
    [Button.name]: Button,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const {
      depart_bazaar,
      depart_project,
      depart_design,
      fnumber,
      isrespposition,
      name,
    } = route.query;
    const dataMap = reactive({
      name: "",
      isLoading: true,
      loading: true,
      comname: "" as any,
      currentType: {} as any,
      dataList: {} as any,
      showAction: false,
      typeList: [] as any,
      activeIds: [] as number[],
    });
    dataMap.comname = name;

    const back = () => {
      router.go(-1);
    };
    const toggle = () => {
      dataMap.showAction = true;
    };

    const doScroll = (event: any) => {
      const scrollLeft = event.target.scrollLeft;
      const scrollBoxs = document.getElementsByClassName("js_scroll_box");
      for (let i = 0; i < scrollBoxs.length; i++) {
        scrollBoxs[i].scrollLeft = scrollLeft;
      }
    };
    const onSelect = (event: any) => {
      dataMap.currentType = {
        name: event.name,
        value: event.value,
      };
      getData();
    };
    const getData = () => {
      dataMap.loading = true;
      dataMap.dataList = {};
      apiBiPeUserList({
        name: dataMap.name,
        fnumber: fnumber,
        type: dataMap.currentType.value,
        isrespposition: isrespposition,
      }).then((res: any) => {
        if (res.status == 200) {
          res.tags.forEach((el: any) => {
            el.width = el.width ? el.width : 6;
          });
          dataMap.dataList = res;
          setTimeout(() => {
            dataMap.activeIds = [];
            showAll();
            initScroll(res.tags);
            dataMap.loading = false;
          }, 300);
        } else {
          dataMap.loading = false;
        }
      });
    };
    const initTypeList = () => {
      if (depart_bazaar === "1") {
        dataMap.typeList.push({
          name: "业务部",
          value: "1",
        });
      }
      if (depart_design === "1") {
        dataMap.typeList.push({
          name: "设计部",
          value: "2",
        });
      }
      if (depart_project === "1") {
        dataMap.typeList.push({
          name: "工程部",
          value: "3",
        });
      }
      if (dataMap.typeList.length) {
        dataMap.currentType = dataMap.typeList[0];
        getData();
      } else {
        Toast("您无查看任何部门的权限");
      }
    };
    initTypeList();
    const initScroll = (tags: any) => {
      const scrollContent = document.getElementsByClassName("js_scroll_content");
      let widthLen = 0;
      for (let n = 1; n < tags.length; n++) {
        widthLen = widthLen + tags[n].width;
      }
      for (let i = 0; i < scrollContent.length; i++) {
        let el = <HTMLElement>scrollContent[i];
        el.style.width = widthLen + "rem";
      }
      if (tags && tags[0] && tags[0].width) {
        const fixedTdH = document.getElementsByClassName("js_fixed_td_h");
        for (let e = 0; e < fixedTdH.length; e++) {
          let el = <HTMLElement>fixedTdH[e];
          el.style.paddingLeft = tags[0].width + "rem";
        }
      }
    };

    const checkIndex = (index: number) => {
      if (dataMap.activeIds.length === 0) {
        dataMap.activeIds.push(index);
        return;
      }
      if (dataMap.activeIds.indexOf(index) != -1) {
        for (let i = 0; i < dataMap.activeIds.length; i++) {
          if (dataMap.activeIds[i] == index) {
            dataMap.activeIds.splice(i, 1);
          }
        }
      } else {
        dataMap.activeIds.push(index);
      }
    };

    const showAll = () => {
      if (dataMap.dataList.data_list.length < dataMap.activeIds.length) {
        dataMap.activeIds = [];
      } else {
        dataMap.activeIds = [];
        for (let i = 0; i < dataMap.dataList.data_list.length; i++) {
          dataMap.activeIds.push(i);
        }
        dataMap.activeIds.push(-1);
      }
    };
    watch(
      () => dataMap.name,
      () => {
        getData();
      }
    );
    return {
      ...toRefs(dataMap),
      back,
      checkIndex,
      toggle,
      doScroll,
      onSelect,
      showAll,
    };
  },
});
</script>

<style lang="less" scoped>
.list {
  .table {
    .table_bg:nth-of-type(2n) {
      background-color: #f2f2f2;
      .fixed_td {
        background-color: #f2f2f2;
      }
    }
    .table_content {
      height: calc(100vh - 170px);
      overflow: auto;
    }
    .table_col {
      display: inline-block;
      white-space: nowrap;
      height: 54px;
      line-height: 54px;
      text-align: center;
      font-size: 13px;
      width: 6rem;
      border-right: 1px solid #f0f0f0;
    }
    .table_row {
      position: relative;
      border-bottom: 1px solid #f0f0f0;
    }
    .scroll_box {
      overflow-x: scroll;
      > div {
        width: 100rem;
      }
    }
    .fixed_td {
      position: absolute;
      left: 0;
      z-index: 60;
    }

    .icon-down {
      position: absolute;
      top: 0;
      right: 12px;
      font-size: 16px;
      height: 50px;
      line-height: 50px;
    }
    .up {
      transform: rotate(180deg);
    }
    .last-line {
      div {
        border-color: transparent;
      }
    }
    .deactive-title {
      background: rgba(#f9c956, 0.2);
      div {
        border-color: transparent;
      }
    }
    .data-none {
      padding: 20px 0;
    }
  }
  .sticky {
    top: 0;
    z-index: 99;
  }
  .active-total {
    background-color: rgba(#aabcee, 0.6) !important;
    div {
      border-color: transparent !important;
    }
  }
  .deactive-total {
    background-color: rgba(#aabcee, 0.3) !important;
    div {
      border-color: transparent;
    }
  }
}
</style>
